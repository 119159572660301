module.exports = {
  path: '/authority',
  template: {
    data: [
      'TABLE_TABLE_TAG_DELETE_TAG',
      'TABLE_TABLE_TAG_ADD_TAG'
    ],
    status: 0,
    code: 100,
    message: null,
  }
}