/**
 * @Description: 用户信息全局状态
 * @Author: jiangang
 * @Date: 2022-04-01
 * @LastEditTime: 11:21:50
 */
import getAuthTree from '@mocks/get/authTree';
import getAuthority from '@mocks/get/authority';
import { mock, http } from '@/http';
import { getStorage, setStorage } from '@utils/storage';

const state = {
  baseInfo: {},
  // 所有的权限code
  authorities: [],
  // 所有的角色
  roles: [],
  // 权限树
  authorityTree: [],

};

const findPermissionId = (value, list) => {
  let permissionId = [];
  if (list && list.length && value) {
    list.some(item => {
      if (item.id === value.parentId) {
        permissionId = [item.code, ...permissionId];
        if (item.parentId !== null) {
          permissionId = [...findPermissionId(item, list), ...permissionId];
        }
        return true;
      }
    });
  }
  return permissionId;
};

const treeToArray = (list) => {
  let arr = [];
  list.forEach(item => {
    const menu = JSON.parse(JSON.stringify(item));
    delete menu.children;
    arr = [...arr, menu];
    if (item.children && item.children.length > 0) {
      arr = [...arr, ...treeToArray(item.children)];
    }
  });
  return arr;
};

const filterTree = (list, authorities, value) => {
  const tree = value.map(item => {
    if (item.type !== 'MENU') {
      const treeArray = treeToArray(list);
      const permissionId = findPermissionId(item, treeArray).join('_');
      const permissionAction = permissionId + '_' + item.code;
      item.abled = authorities.includes(permissionAction);
    }
    if (item.children && item.children.length > 0) {
      item.children = filterTree(list, authorities, item.children);
    }
    return item;
  });
  return tree;
};

const doLoop = (list) => {
  const treeArray = treeToArray(list);
  let arr = [];
  treeArray.forEach(item => {
    if (item.type !== 'MENU' && item.abled) {
      let permissionId = findPermissionId(item, treeArray).join('_');
      const index = arr.findIndex(val => val.permissionId === permissionId);
      if (index > -1) {
        arr[index].actionEntitySet.push({
          action: item.code,
          describe: item.label
        });
        arr[index].actionList.push(item.code);
      } else {
        arr.push({
          permissionId,
          permissionName: treeArray.find(i => i.id === item.parentId).label,
          // roleId: 'system',
          actionEntitySet: [{
            action: item.code,
            describe: item.label
          }],
          actionList: [item.code]
        });
      }
    }
  });
  return arr;
};

const actions = {
  updateAuthorityTree({ commit, state }) {
    return new Promise((resolve) => {
      let list = [];
      mock.get('/auth-tree').then(res => {
        list = res;
      }).catch(() => {
        list = getAuthTree.template.data;
      }).finally(() => {
        const tree = filterTree(list, state.authorities, list);
        resolve(tree);
        commit('authorityTree', tree);
      });
    });
  },
  updateAuthority({ dispatch, commit }, param) {
    return new Promise((resolve) => {
      let list = [];
      mock.get('/authority').then(res => {
        list = res;
      }).catch(() => {
        list = getAuthority.template.data;
      }).finally(() => {
        commit('authorities', list);
        dispatch('updateAuthorityTree').then(res => {
          const roleObj = doLoop(res);
          resolve(roleObj);
          commit('roles', roleObj);
        });
      });
    });
  },
  login({ commit }, to) {
    return new Promise((resolve, reject) => {
      http.get('/open/auth/login/idm', { params: { code: to.query.code } }).then(res => {
        setStorage('TOKEN', res.token);
        http.defaults.headers.common['TOKEN'] = res.token;
        commit('BASEINFO', res);
        resolve();
      }).catch(err => {
        if (err.code === 302) {
          window.location.href = err.data;
        }
        reject(err);
      });
    });
  }
};

const mutations = {
  BASEINFO(state, update) {
    state.baseInfo = update;
  },
  authorities(state, update) {
    state.authorities = update;
  },
  roles(state, update) {
    state.roles = update;
  },
  authorityTree(state, update) {
    state.authorityTree = update;
  },
};


export default {
  namespaced: true,
  state,
  mutations,
  actions
};
