/**
 * @Description: 首页
 * @Author: huping
 * @Date: 2021-08-12 09:59:49
 * @LastEditTime: 2021-08-16 14:51:17
 */

<template>
  <div class="page-container flex-column flex-center">
    <img class="logo" :src="homeIcon" alt="">
    <p class="text">欢迎来到后端管理系统</p>
<!--    <pre style="position:absolute; right: 0; top: 200px">{{authorities}}</pre>-->
  </div>
</template>

<script>
import {mapState} from 'vuex';
import homeIcon from '@/assets/imgs/home-icon.png'

export default {
  data() {
    return {
      homeIcon
    }
  },
  methods: {
  },
  mounted() {
  },
  computed: {
    ...mapState('userInfo', ['authorities']),
  },
};
</script>

<style lang="scss" scoped>
  .page-container {
    min-height: 100%;
    .text {
      font-size: 32px;
      color: #666666;
      letter-spacing: 0;
      text-align: center;
      line-height: 32px;
      margin-top: 40px;
    }
  }
</style>
