/**
 * @Description: vue config文件
 * @Author: jiangang
 * @Date: 2022-03-07
 * @LastEditTime: 10:22:05
 */
import axios from 'axios';
import {setStorage} from '@/utils/storage';
import router from '@/router';
import { removeStorage } from '../utils/storage';
import store from '@/store';

const SUCCESS_CODE = 200;
// 服务端响应错误字典
const serverErrorMap = new Map([
  [400, '身份未认证'],
  [401, '身份认证失败'],
  [403, '拒绝访问'],
  [404, '身份校验失败'],
  [405, '身份受限定'],
  [406, '登录失效请重新登录'],
  [407, '账户不存在'],
  [441, '非法授权操作'],
  [500, '服务器错误'],
  [555, '外部服务异常'],
  [911, '错误的请求'],
  [913, '过期或失效的操作，请刷新页面再试！'],
  [1001, '参数错误'],
  [1002, '参数重复'],
  [999, '连接服务器失败'],
]);
// http状态码
const httpStatusMap = new Map([
  [404, '访问的地址不存在'],
  [500, '服务器错误']
])
const cancelList = []

const responseResolve = (res) => {
  res.cancelToken = new axios.CancelToken(function exector(c) {
    cancelList.push(c)
  })
  const {authorization} = res.headers
  if (authorization) {
    setStorage('authorization', authorization)
  }
  const { data: json = {} } = res;
  const { code, description, data = {} } = json;
  switch (code) {
    case SUCCESS_CODE: {
      return Promise.resolve(data);
    }
    default: {
      if (code === 400) {
        cancelList.forEach(cancel => {
          cancel()
        })
      }
      if (code === 406) {
        setTimeout(() => {
          router.replace('/login')
        }, 1000)
      }
      let error = serverErrorMap.get(code)
      if (code === 500 && description === '不允许访问') {
        router.replace('/no-permission')
        // error = '您没有当前业务权限，请联系管理员配置'
        // 不报错，直接跳转到无权限页面
        return Promise.resolve(data);
      }
      // 未登录状态
      if (code === 10001) {
        removeStorage('TOKEN');
        store.dispatch('userInfo/login', { query: {} });
        return Promise.reject({ code });
      }
      const responseError = {
        serverErrorCode: code,
        ...json,
        description,
        error
      };
      return Promise.reject(responseError);
    }
  }
};
const defaultServerErrorPrefix = '连接出错';

const responseError = (err) => {
  const status = err && err.response ? (err.response.status || err.response.serverErrorCode) : 999;
  const message = err.description || httpStatusMap.get(status) || serverErrorMap.get(status) || `${defaultServerErrorPrefix}(${status})`;
  return Promise.reject(new Error(message));
};
export {
  responseResolve,
  responseError,
};
