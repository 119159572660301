<template>
    <div></div>
</template>

<script>
export default {
  // 此处快捷登录以知新项目为例，其他项目只需要修改相关服务接口
  name: 'mixin',
  methods: {
    quickLogin(type) {
      this.loading = true
      if (type === 'IDM') {
        this.IDMinit()
      } else {
        this.feishuInit()
      }
    },
    IDMinit() {
      this.$http.get('/authorize/idm/coordinate').then(res => {
        const {url, clientId} = res
        window.location.href = `${url}/oauth2.0/authorize?client_id=${clientId}&redirect_uri=${this.redirectUri}&response_type=code`
      }).catch(() => {
        this.loading = false
      })
    },
    feishuInit() {
      this.$http.get('/authorize/fs/coordinate').then(res => {
        const {appId} = res
        window.location.href = `https://open.feishu.cn/open-apis/authen/v1/index?app_id=${appId}&redirect_uri=${this.redirectUri}&state=feishu`
      }).catch((err) => {
        this.loading = false
        this.$message({
          type: 'error',
          message: err.message || '登录失败',
          showClose: true
        })
      })
    },
    feishuLogin(code) {
      this.$http.get(`/authorize/fs/authentication/${code}`).then(() => {
        this.$router.replace(this.activeTab?.path || '/welcome')
      }).catch(err => {
        this.$message({
          message: err.error || '登陆失败',
          type: 'error',
          showClose: true
        })
      })
    },
    IDMLogin(code) {
      this.$http.get(`/authorize/idm/authentication/${code}`, {
        params: {
          redirectUri: this.redirectUri
        }
      }).then(() => {
        this.$router.replace(this.activeTab?.path || '/welcome')
      }).catch(err => {
        this.$message({
          message: err.error || '登陆失败',
          type: 'error',
          showClose: true
        })
      })
    },
  },
  computed: {
    redirectUri() {
      return `${window.location.protocol}//${window.location.host}/login`
    }
  },
  mounted() {
    const {code, state} = this.$route.query
    if (code && state === 'feishu') {
      this.feishuLogin(code)
    } else if (code) {
      this.IDMLogin(code)
    }
  }
}
</script>

<style scoped>

</style>
