var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(!_vm.menuList.length),expression:"!menuList.length"}],staticClass:"nav-menu-vertical flex-column",class:[_vm.theme, _vm.collapse]},[_vm._l((_vm.menuArray),function(item,index){return _c('div',{key:item.id,staticClass:"nav-menu-item",class:{
      'child-active': item.childActive,
      active: item.active,
      'first-level': !item.level,
    },style:(_vm.itemStyle(item, index)),attrs:{"tabindex":item.id},on:{"click":function($event){return _vm.selectItem(item)},"mouseleave":function($event){return _vm.handMouseleave({ parents: [] })},"mouseenter":function($event){return _vm.handleCollapseHover(item)}}},[(item.firsrChild && _vm.collapse === 'collapse')?_c('div',{staticClass:"nav-menu-content parent-name",on:{"click":function($event){$event.stopPropagation();return (() => {}).apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.menuArray[index - 1].title)+" ")]):_vm._e(),(
        _vm.collapse === 'collapse' &&
        !item.level &&
        !(item.children && item.children.length)
      )?_c('el-tooltip',{attrs:{"effect":_vm.theme,"content":item.title,"placement":"right"}},[_c('div',{staticClass:"nav-menu-content flex-row flex-item",staticStyle:{"position":"relative","z-index":"999"}},[_c('i',{staticClass:"menu-icon",class:item.icon})])]):_c('div',{staticClass:"nav-menu-content flex-row flex-item"},[_c('i',{staticClass:"menu-icon",class:item.icon}),_c('div',{staticClass:"title text-ellipsis flex1"},[_vm._v(_vm._s(item.title))]),(item.children && item.children.length)?_c('i',{staticClass:"menu-icon newhope-iconfont icon-base-right",class:{
          'expand-status-icon': item.expand && _vm.collapse === 'expand',
        }}):_vm._e()]),_c('div',{staticClass:"nav-item-bg"})],1)}),_c('div',{staticClass:"flex1"}),_c('div',{staticClass:"footer flex-row flex-item"},[_c('div',{staticClass:"flex1"}),_c('i',{staticClass:"newhope-iconfont icon-base-skin",on:{"click":_vm.themeSwitch}}),(_vm.collapse === 'expand')?_c('i',{staticClass:"newhope-iconfont icon-base-packmenu",on:{"click":_vm.collapseSwitch}}):_c('i',{staticClass:"newhope-iconfont icon-base-exmenu",on:{"click":_vm.collapseSwitch}})])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }