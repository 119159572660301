/**
 * @Description: 路由路径
 * @Author: jiangang
 * @Date: 2022-04-27
 * @LastEditTime: 11:38:17
 */
import welcome from '@/views/welcome';
import login from '@views/login';
import noPermission from '@/views/common/no-permission';

/**
 * meta: {
      title: '首页',
      requireAuth: false, 跳转路由时是否鉴权
      isFull: true, 是否全屏展示此页面 如Login页面就需要为true
      keepAlive: false, 组件是否需要缓存
    },
 */

const defaultPath = '/welcome'
const routes = [
  {
    path: '/',
    redirect: defaultPath
  },
  {
    path: '/welcome',
    name: 'welcome',
    meta: {
      title: '首页',
    },
    component: welcome,
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '登录',
      isFull: true,
    },
    component: login,
  },
  {
    path: '/no-permission',
    name: 'noPermission',
    meta: {
      title: '没有权限',
    },
    component: noPermission,
  },
  {
    path: '/404',
    name: '404',
    meta: {
      title: '页面不存在'
    },
    component: () => import('@views/common/404'),
  }
];

export default routes
