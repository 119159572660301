// 为日期增加格式化方法,y年M月d日h时m分s秒q季度S毫秒,可随意组合
// IOS 使用 .replace(/-/g, "/") 转换后  new Date( data.replace(/-/g, "/") )

Date.prototype.format = function(fmt) {
  const o = {
    'M+': this.getMonth() + 1, // 月份
    'd+': this.getDate(), // 日
    'h+': this.getHours(), // 小时
    'm+': this.getMinutes(), // 分
    's+': this.getSeconds(), // 秒
    'q+': Math.floor((this.getMonth() + 3) / 3), // 季度
    'w+': ['日', '一', '二', '三', '四', '五', '六'][this.getDay()], // 星期
    S: this.getMilliseconds() // 毫秒
  };
  if (/(y+)/.test(fmt)) {
    // eslint-disable-next-line
    fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  for (const k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      // eslint-disable-next-line
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1)
        ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)));
    }
  }
  return fmt;
}
