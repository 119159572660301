/**
 * @Description: 全局文件
 * @Author: huping
 * @Date: 2021-08-17 17:05:28
 * @LastEditTime: 2021-09-07 15:06:13
 */
import globalApi from '@/api/global';
import {setStorage} from '@/utils/storage';

const global = {
  namespaced: true,
  state: () => ({
    token: '',
    previousRouter: {},
    globalComponentDisplay: false
  }),
  mutations: {
    updateToken(state, payload) {
      state.token = payload;
      setStorage('token', payload)
    },
    updatePreviousRouter(state, payload) {
      state.previousRouter = payload;
    },
    globalComponentDisplay(state, payload) {
      state.globalComponentDisplay = payload;
    },
  },
  actions: {
    updatePreviousRouter({ commit }, payload) {
      commit('updatePreviousRouter', payload);
    },
    login({ commit }, payload) {
      return globalApi.login(payload)
        .then((res = []) => {
          commit('updateToken', res);
          localStorage.setItem('TOKEN', JSON.stringify(res));
        })
        .catch(err => {
          return Promise.reject(err);
        });
    },
  },
  getters: {},
};

export default global;
