/**
 * @Description: 暂无描述
 * @Author: jiangang
 * @Date: 2022-03-29
 * @LastEditTime: 13:57:03
 * {
 *   icon: 菜单图标，
 *   path： 菜单跳转路径
 *   title： 页面title
 *   name： 跳转组件name
 * }
 */

const business = [
  {
    icon: 'newhope-iconfont icon-base-sort',
    functionName: '表格',
    id: 1,
    subFunctionList: [{
      functionName: '带边框表格',
      icon: 'newhope-iconfont icon-base-decrease',
      url: '/demo/business/table/table-border',
      name: 'table-border',
      requireAuth: false,
      keepAlive: false,
      id: 11,
    }, {
      functionName: '多选表格',
      icon: 'newhope-iconfont icon-base-checkbox',
      url: '/demo/business/table/table-multi-select',
      name: 'table-multi-select',
      requireAuth: false,
      keepAlive: true,
      id: 12,
    }, {
      functionName: '换行表格',
      icon: 'newhope-iconfont icon-base-switch',
      url: '/demo/business/table/table-wrap',
      name: 'table-wrap',
      requireAuth: false,
      keepAlive: false,
      id: 13,
    }, {
      functionName: '带排序表格',
      icon: 'newhope-iconfont icon-base-order',
      url: '/demo/business/table/table-sort',
      name: 'table-sort',
      requireAuth: false,
      keepAlive: false,
      id: 14,
    }, {
      functionName: '带标签操作表格',
      icon: 'newhope-iconfont icon-base-level',
      url: '/demo/business/table/table-tag',
      name: 'table-tag',
      requireAuth: false,
      keepAlive: false,
      id: 15,
    }, {
      functionName: '带筛选的表格',
      icon: 'newhope-iconfont icon-base-sign',
      url: '/demo/business/table/index',
      name: 'table-filter',
      requireAuth: false,
      keepAlive: false,
      id: 16,
    }]
  },
  {
    icon: 'newhope-iconfont icon-base-list',
    functionName: '表单',
    id: 2,
    subFunctionList: [
      {
        functionName: '基础表单',
        icon: 'newhope-iconfont icon-base-edit2',
        name: 'form-basic',
        requireAuth: false,
        keepAlive: false,
        id: 21,
        subFunctionList: [
          {
            functionName: '基础表单21',
            icon: 'newhope-iconfont icon-base-edit2',
            url: '/demo/business/form/form-basic',
            name: 'form-xxx',
            requireAuth: false,
            keepAlive: false,
            id: 211,
          },
          {
            functionName: '基础表单22',
            icon: 'newhope-iconfont icon-base-edit2',
            url: '/demo/business/form/form-basic',
            name: 'form-yyy',
            requireAuth: false,
            keepAlive: false,
            id: 212,
          },
        ],
      }, {
        functionName: '条件选择',
        icon: 'newhope-iconfont icon-base-ascend',
        url: '/demo/business/form/form-select-criteria',
        name: 'form-select-criteria',
        requireAuth: false,
        keepAlive: false,
        id: 22,
      }, {
        functionName: '表单-树状结构',
        icon: 'newhope-iconfont icon-base-warn',
        url: '/demo/business/form/form-tree',
        name: 'form-tree',
        requireAuth: false,
        keepAlive: false,
        id: 23,
      }, {
        functionName: '标签-树状结构',
        icon: 'newhope-iconfont icon-base-copy',
        url: '/demo/business/form/tab-tree',
        name: 'tab-tree',
        requireAuth: false,
        keepAlive: false,
        id: 24,
      },
    ]
  },
]
const development = [
  {
    icon: 'newhope-iconfont icon-base-list',
    functionName: '表单',
    id: 1,
    subFunctionList: [{
      functionName: '带缓存的表单',
      icon: 'newhope-iconfont icon-base-fond-F',
      url: '/demo/development/keep-alive',
      name: 'keep-alive',
      requireAuth: false,
      keepAlive: true,
      id: 11,
    }, {
      functionName: '未缓存的表单',
      icon: 'newhope-iconfont icon-base-fond',
      url: '/demo/development/not-keep-alive',
      name: 'not-keep-alive',
      id: 12,
    }, {
      functionName: '带查询的列表',
      icon: 'newhope-iconfont icon-base-search',
      url: '/demo/development/filter-table',
      name: 'filter-table',
      id: 13,
    }],
  },
  {
    functionName: '通用页面',
    icon: 'newhope-iconfont icon-base-warn',
    name: 'commonPage',
    id: 2,
    subFunctionList: [
      {
        functionName: '无权限的页面',
        icon: 'newhope-iconfont icon-base-lock',
        url: '/common/no-permission',
        name: 'noPermission',
        id: 21
      }, {
        functionName: '错误的地址',
        icon: 'newhope-iconfont icon-base-del-F',
        url: '/common/404/index',
        name: 'not-found',
        id: 22,
      }, {
        functionName: '全局组件',
        icon: 'newhope-iconfont icon-base-send',
        url: '/demo/development/globalComponent',
        name: 'globalComponent',
        id: 23,
      }, {
        functionName: '监听tab关闭',
        icon: 'el-icon-rank',
        url: '/demo/development/tab-hook',
        name: 'tab-hook',
        id: 24,
      }]
  }, {
    icon: 'el-icon-video-play',
    functionName: '无子集菜单',
    id: 3,
    url: '/demo/development/first-level-page',
    name: 'first-level-page',
  }
]
module.exports = {
  menuList: {
    business,
    development
  },
}
