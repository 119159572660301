/**
 * @Description: 本地存储工具
 * @Author: jiangang
 * @Date: 2022-03-07
 * @LastEditTime: 10:00:33
 */

/**
 * 设置本地缓存
 * @param {string} key
 * @param {any} val
 */
export function setStorage(key, val) {
  localStorage.setItem(key, JSON.stringify(val));
}

/**
 * 获取本地缓存
 * @param {string} key
 */
export function getStorage(key) {
  const val = localStorage.getItem(key);
  let obj = null
  try {
    obj = JSON.parse(val)
    return obj
  } catch (e) {
    return val
  }
}

/**
 * 移除某一项缓存
 * @param {string} key
 */
export function removeStorage(key) {
  localStorage.removeItem(key);
}

/**
 * 清除所有缓存
 */
export function clearStorage() {
  localStorage.clear();
}

/**
 * 设置本地临时缓存
 * @param {string} key
 * @param {any} val
 */
export function setSession(key, val) {
  sessionStorage.setItem(key, JSON.stringify(val));
}

/**
 * 获取本地临时缓存
 * @param {string} key
 */
export function getSession(key) {
  const val = sessionStorage.getItem(key);
  let obj = null
  try {
    obj = JSON.parse(val)
    return obj
  } catch (e) {
    return val
  }
}

/**
 * 移除某一项临时缓存
 * @param {string} key
 */
export function removeSession(key) {
  sessionStorage.removeItem(key);
}

/**
 * 清除所有临时缓存
 */
export function clearSession() {
  sessionStorage.clear();
}
