module.exports = {
  path: '/auth-tree',
  template: {
    data: [
      {
        label: '表格',
        id: 1,
        parentId: null,
        code: 'TABLE',
        type: 'MENU',
        children: [{
          label: '带边框表格',
          id: 11,
          parentId: 1,
          code: 'TABLE_BORDER',
          type: 'MENU',
        }, {
          label: '多选表格',
          id: 12,
          parentId: 1,
          code: 'TABLE_MULTI_SELECT',
          type: 'MENU',
        }, {
          label: '换行表格',
          id: 13,
          parentId: 1,
          code: 'TABLE_WRAP',
          type: 'MENU',
        }, {
          label: '带排序表格',
          id: 14,
          parentId: 1,
          code: 'TABLE_SORT',
          type: 'MENU',
        }, {
          label: '带标签操作表格',
          id: 15,
          parentId: 1,
          code: 'TABLE_TAG',
          type: 'MENU',
          children: [
            {
              label: '增加标签',
              id: 151,
              parentId: 15,
              code: 'ADD_TAG',
              type: 'operate'
            },
            {
              label: '删除标签',
              id: 152,
              parentId: 15,
              code: 'DELETE_TAG',
              type: 'operate'
            }
          ]
        }, {
          label: '带筛选的表格',
          id: 16,
          parentId: 1,
          code: 'TABLE_FILTER',
          type: 'MENU',
        }]
      },
      {
        label: '表单',
        id: 2,
        parentId: null,
        code: 'FROM',
        type: 'MENU',
        children: [
          {
            label: '基础表单',
            id: 21,
            parentId: 2,
            code: 'FORM_BASIC',
            type: 'MENU',
            children: [
              {
                label: '基础表单21',
                id: 211,
                parentId: 21,
                code: 'FROM_XXX',
                type: 'MENU',
                children: [
                  {
                    label: '删除标签',
                    id: 2111,
                    parentId: 211,
                    code: 'DELETE_TAG',
                    type: 'operate'
                  }
                ]
              },
              {
                label: '基础表单22',
                id: 212,
                parentId: 21,
                code: 'FROM_YYY',
                type: 'MENU',
              },
            ],
          }, {
            label: '条件选择',
            id: 22,
            parentId: 2,
            code: 'FORM_SELECT_SRITERIA',
            type: 'MENU',
          }, {
            label: '表单-树状结构',
            id: 23,
            parentId: 2,
            code: 'FORM_TREE',
            type: 'MENU',
          }, {
            label: '标签-树状结构',
            id: 24,
            parentId: 2,
            code: 'TAB_TREE',
            type: 'MENU',
          },
        ]
      }
    ],
    status: 0,
    code: 100,
    message: null,
  }
}
