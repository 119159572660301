/**
 * @Description: App主入口
 * @Author: huping
 * @Date: 2021-08-06 10:30:18
 * @LastEditTime: 2021-08-20 16:15:02
 */
<template>
  <div id="app">
    <router-view v-if="$route.meta.isFull"></router-view>
    <template v-else>
      <nh-header />
      <nh-main />
    </template>
  </div>
</template>

<script>
import nhHeader from '@/components/Header.vue';
import nhMain from '@/views/Main.vue';
export default {
  components: {
    nhHeader,
    nhMain,
  }
};
</script>


<style lang="scss">
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100vw;
    height: 100vh;
    position: relative;
  }
</style>
