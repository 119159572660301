/**
 * @Description: 全局api
 * @Author: huping
 * @Date: 2021-08-20 15:18:29
 * @LastEditTime: 2021-08-20 15:19:46
 */

import {http, mock} from '@/http';

export default {
  login: params => http.post('/user/login', params),
  loginMock: params => mock.post('/user/login', params),
};
