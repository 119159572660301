/**
* @Description: Main文件
* @Author: huping
* @Date: 2021-08-16 14:20:25
* @LastEditTime: 2021-08-27 14:03:11
*/

<template>
  <div class="main flex-row" v-loading="loading">
    <global v-if="globalComponentDisplay"></global>
    <aside-menu/>
    <section class="content-wrapper flex-column flex1">
      <navTab/>
      <div class="page-content-container flex-column flex1">
        <!-- 匹配到的路由 -->
        <keep-alive :exclude="exclude" v-if="userId">
          <router-view></router-view>
        </keep-alive>
        <no-permission v-else-if="!loading"></no-permission>
      </div>
    </section>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import AsideMenu from '@/components/system/AsideMenu';
import navTab from '@/components/system/NavTab';
import global from './common/global-component'
import noPermission from '@/views/common/no-permission';
import {http} from '@/http';

export default {
  name: 'nhMain',
  data() {
    return {
      userId: '4456',
      loading: false
    };
  },
  components: {
    AsideMenu,
    navTab,
    global,
    noPermission
  },
  methods: {
    getUserInfo() {
      // this.loading = true
      // this.$mock.get('/userinfo').then(res => {
      //   this.userId = res.id
      //   this.$store.commit('userInfo/baseInfo', res)
      // }).catch(err => {
      //   const res = getUserInfo.template.data
      //   this.userId = res.id
      //   this.$store.commit('userInfo/baseInfo', res)
      //   this.$message({
      //     message: err.error || '获取用户失败，请重新登录！',
      //     type: 'error',
      //     showClose: true
      //   })
      //   setTimeout(() => {
      //     this.$router.replace('/login')
      //   }, 3000)
      // }).finally(() => {
      //   this.loading = false
      // })
      // this.$store.dispatch('userInfo/getUserInfo', { fromPath: this.$route.path });
    }
  },
  mounted() {
    this.getUserInfo()
    this.$store.commit('systemMenu/onTabClose', (current, next) => {
      console.log(`关闭${current.title}`)
      next()
    })
  },
  computed: {
    ...mapState('systemMenu', ['tabList', 'activeTab', 'menuList']),
    ...mapState('userInfo', ['baseInfo']),
    ...mapState('global', ['globalComponentDisplay']),
    // 避免关闭tab之后重新打开页面没重新加载
    exclude() {
      const tabNames = this.tabList.map(item => {
        return item.name
      })
      const treeToArry = (list) => {
        const menuArray = []
        function doloop(listTree) {
          for (const item of listTree) {
            if (item.children && item.children.length) {
              doloop(item.children)
            }
            if (item.name) {
              menuArray.push(item)
            }
          }
        }
        doloop(list)
        return menuArray
      }
      // 路由中不缓存的页面,不在tab中的页面均不缓存
      return treeToArry(this.menuList).filter(item => {
        return !item.keepAlive || !tabNames.includes(item.name)
      }).map(item => {
        return item.name
      })
    }
  }
};
</script>

<style scoped lang='scss'>
  .main {
    width: 100vw;
    height: calc(100vh - 64px);
    // overflow: hidden;
    position: absolute;
    left: 0;
    right: 0;
    top: 64px;
    bottom: 0;

    .content-wrapper {
      /*transition: all .5s;*/
      padding: 16px;
      box-sizing: border-box;
      background: #F5F5F5;
      overflow-x: auto;

      .page-content-container {
        background-color: #fff;
        overflow: auto;
        flex-shrink: 0;
      }
    }
  }
</style>
