/**
 * @Description: Header公共头组件
 * @Author: huping
 * @Date: 2021-08-06 10:54:02
 * @LastEditTime: 2022-06-16 11:31:01
 */

<template>
  <div class="header flex-row flex-center">
    <div class="header-logo flex-row flex-center">
      <img class="header-logo-img" :src="images.logo" alt="">
    </div>
    <div class="header-nav flex-row flex-item flex1">
      <!-- <div class="header-nav-btn flex-column"
           :class="{active: activeType === btn.value}"
           @click="btnClick(btn)"
           v-for="btn in navBtns" :key="btn.value">
        <div class="flex1"></div>
        <p>{{btn.name}}</p>
        <div class="flex1"></div>
        <div class="bottom-line"></div>
      </div> -->
      <div class="flex1"></div>
      <div class="system-func flex-row flex-item">
        <div class="system-btn">
          <i class="el-icon-search"></i>
        </div>
        <div class="system-btn">
          <i class="el-icon-close-notification"></i>
        </div>
        <div class="system-btn">
          <el-dropdown @command="handleSelect">
            <div class="user-info flex-row flex-center">
              欢迎您,
              <div class="user-name">{{baseInfo.name}}</div>
              <i class="el-icon-arrow-down"></i>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="loginOut">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex';
import {getStorage, setStorage} from '@utils/storage'

const images = {
  logo: require('@/assets/imgs/header_logo.svg')
}
export default {
  name: 'nhHeader',
  data() {
    return {
      images,
      navBtns: [{
        name: '业务版',
        value: 'business'
      }, {
        name: '开发版',
        value: 'development'
      }],
      activeType: ''
    };
  },
  methods: {
    ...mapActions('systemMenu', ['doSwitchTab', 'updateMenuList']),
    btnClick(item) {
      this.activeType = item.value;
      setStorage('left-menu-type', item.value)
      this.updateMenuList(item.value)
      this.$store.commit('systemMenu/updateTabList', [])
      this.doSwitchTab(null)
    },
    toHome() {
      this.$router.replace('/welcome')
    },
    loginOut() {
      this.$router.replace('/login')
      this.$store.commit('systemMenu/updateTabList', [])
    },
    handleSelect(command) {
      this[command]()
    }
  },
  mounted() {
    this.activeType = getStorage('left-menu-type') || this.navBtns[0].value
  },
  computed: {
    ...mapState('userInfo', ['baseInfo']),
  }
};
</script>

<style scoped lang='scss'>
  .header {
    width: 100%;
    height: 64px;
    background: #ffffff;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
    .header-logo {
      width: 280px;
      padding-left: 20px;
      // .header-logo-img {
      //   width: 32px;
      //   margin-right: 12px;
      // }
      // .header-logo-des {
      //   font-size: 18px;
      //   font-family: MyriadPro-Semibold, MyriadPro;
      //   font-weight: 600;
      //   color: #333333;
      //   line-height: 23px;
      // }
    }
    .header-nav {
      text-align: center;
      height: 100%;
      .header-nav-btn {
        cursor: pointer;
        height: 100%;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #333333;
        margin-left: 72px;
        .bottom-line {
          height: 2px;
          background-color: $primary;
          opacity: 0;
        }
        &.active {
          color: $primary;
          .bottom-line {
            opacity: 1;
          }
        }
      }
      .system-func {
        .system-btn {
          cursor: pointer;
          margin-right: 30px;
          .head-portrait {
            border-radius: 50%;
            overflow: hidden;
            background-color: #cccccc;
            height: 24px;
            width: 24px;
            img {
              width: 100%;
            }
          }
          .user-name {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            color: #666666;
            margin: 8px;
          }
          i {
            font-size: 16px;
          }
        }
      }
    }
  }
</style>
