/**
 * @Description: vue入口文件
 * @Author: huping
 * @Date: 2021-08-09 11:07:18
 * @LastEditTime: 2022-11-15 16:34:51
 */

import Vue from 'vue';
import App from './App.vue';
import router from '@/router';
import registerDrag from 'vue-easy-drager'
import buryPoint from 'nh-bury-point';
import NhElementUI from 'nh-element-ui'
import '@/assets/theme-chalk/index.css'
import './utils/format/date'
import store from '@/store';
import { http, mock } from '@/http';
import {getStorage} from '@/utils/storage';
import nhPagination from '@/components/nh-pagination'
import nhCrumbs from '@/components/nh-crumbs'
import PermissionHelper from '@/core/permission'

Vue.config.productionTip = false;
Vue.use(NhElementUI, { size: 'medium' })

Vue.use(PermissionHelper)

registerDrag(Vue)

Vue.component('nh-pagination', nhPagination)
Vue.component('nh-crumbs', nhCrumbs)
const isDev = process.env.NODE_ENV !== 'production';

Object.defineProperty(Vue.prototype, '$http', {
  get: function() {
    return http
  },
});
Object.defineProperty(Vue.prototype, '$mock', {
  get: function() {
    // 防止mock被发布到线上
    return isDev ? mock : http;
  },
});
// 正式环境才开启埋点 & 错误上报
const baseInfo = getStorage('baseInfo') || '未登录用户'
if (!isDev) {
  Vue.use(buryPoint, {
    openid: '', // 第三方系统用户id 没有可不传
    project: 'vue-admin', // 项目唯一标识 项目名 必传
    from_channel: 'WEB', // WEB H5
    isProd: false, // 是否生产
    user_name: baseInfo.name,
    user_mobile: baseInfo.phone,
    user_id: baseInfo.subjectId, // 用户id
  });
  Vue.config.errorHandler = function(err, _vm, info) {
    console.log(err, _vm, info)
    // handle error
    // `info` 是 Vue 特定的错误信息，比如错误所在的生命周期钩子
    // 只在 2.2.0+ 可用
    Vue.prototype.$sendPoint.pageJsErrorEvent({
      bus_para: 'type=xxx&data=xxx&colno=xxx&lineno=xxx&message=xxx&xxx=xxx',
    });
  };
}

new Vue({
  router,
  store,
  render: h => h(App),
  beforeCreate(){
    Vue.prototype.$bus = this
  }
}).$mount('#app');
