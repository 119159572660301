<template>
  <div class="global-container">
<!--    全局组件挂载点-->
    <div class="flex-column flex-center" id="global-component-body" v-drag="dragConfig">
      <p>left: {{left}}</p>
      <p>top: {{top}}</p>
<!--      <p>status: {{moveStatus}}</p>-->
      <p>我是全局组件</p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'nhGlobal',
  data() {
    return {
      dragConfig: {
        handler() {
        }
      },
      left: 0,
      top: 0,
      moveStatus: ''
    }
  },
  methods: {
    setSize() {
      const DOM = document.getElementsByClassName('main')[0]
      const el = document.querySelector('#global-component-body')
      const { width, height } = el.getBoundingClientRect()
      if (DOM) {
        const {scrollWidth, scrollHeight} = DOM
        const right = scrollWidth - parseInt(width, 10)
        const bottom = scrollHeight - parseInt(height, 10)
        this.dragConfig.limit = {y: [0, bottom],x: [0, right]}
        this.dragConfig.handler = this.dragHandle
      }
    },
    dragHandle(e) {
      console.log(e)
      this.left = e.left
      this.top = e.top
      this.moveStatus = e.status
    }
  },
  mounted() {
    window.onresize = () => {
      this.setSize()
    }
    this.$nextTick(() => {
      // 设置拖拽范围
      this.setSize()
    })
  },
  computed: {
  }
}
</script>

<style lang="scss" scoped>
  .global-container {
    #global-component-body {
      width: 100px;
      height: 100px;
      background: $primary;
      color: #FFFFFF;
      z-index: 9;
      right: 0;
      bottom: 0;
      user-select: none;
    }
  }
</style>
