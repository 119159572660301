<template>
  <div
    v-loading="!menuList.length"
    class="nav-menu-vertical flex-column"
    :class="[theme, collapse]"
  >
    <div
      class="nav-menu-item"
      v-for="(item, index) in menuArray"
      :tabindex="item.id"
      :key="item.id"
      @click="selectItem(item)"
      @mouseleave="handMouseleave({ parents: [] })"
      @mouseenter="handleCollapseHover(item)"
      :class="{
        'child-active': item.childActive,
        active: item.active,
        'first-level': !item.level,
      }"
      :style="itemStyle(item, index)"
    >
      <div
        @click.stop="() => {}"
        class="nav-menu-content parent-name"
        v-if="item.firsrChild && collapse === 'collapse'"
      >
        {{ menuArray[index - 1].title }}
      </div>
      <el-tooltip
        v-if="
          collapse === 'collapse' &&
          !item.level &&
          !(item.children && item.children.length)
        "
        :effect="theme"
        :content="item.title"
        placement="right"
      >
        <div
          style="position: relative; z-index: 999"
          class="nav-menu-content flex-row flex-item"
        >
          <i class="menu-icon" :class="item.icon"></i>
        </div>
      </el-tooltip>
      <div v-else class="nav-menu-content flex-row flex-item">
        <i class="menu-icon" :class="item.icon"></i>
        <div class="title text-ellipsis flex1">{{ item.title }}</div>
        <i
          v-if="item.children && item.children.length"
          class="menu-icon newhope-iconfont icon-base-right"
          :class="{
            'expand-status-icon': item.expand && collapse === 'expand',
          }"
        ></i>
      </div>
      <div class="nav-item-bg"></div>
    </div>
    <div class="flex1"></div>
    <div class="footer flex-row flex-item">
      <div class="flex1"></div>
      <i @click="themeSwitch" class="newhope-iconfont icon-base-skin"></i>
      <i
        v-if="collapse === 'expand'"
        @click="collapseSwitch"
        class="newhope-iconfont icon-base-packmenu"
      ></i>
      <i
        v-else
        @click="collapseSwitch"
        class="newhope-iconfont icon-base-exmenu"
      ></i>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { getStorage, setStorage } from '@utils/storage';
export default {
  name: 'navMenu',
  data() {
    return {
      theme: 'dark',
      collapse: 'collapse',
      menuArray: [],
      inited: false,
      leaveHandleTimer: null,
      enterHandleTimer: null,
    };
  },
  mounted() {
    this.collapseSwitch();
    this.theme = getStorage('nav-menu-theme') || 'dark';
    this.collapse = getStorage('nav-menu-collapse') || 'collapse';
    // tab驱动菜单
    let timer = null;
    this.$bus.$on('tabClick', item => {
      clearTimeout(timer);
      // 20ms内只触发最后一次，提升批量关闭性能
      timer = setTimeout(() => {
        this.selectItem(item, true);
      }, 20);
    });
  },
  computed: {
    ...mapState('systemMenu', ['menuList', 'activeTab', 'tabList']),
    expandList() {
      return this.menuArray.reduce((pre, item) => {
        const firstLevel = item.level ? [] : [item.id];
        if (item.expand) {
          let nextLevelId = [];
          if (this.collapse === 'expand') {
            // 折叠菜单的子集自身有expand
            nextLevelId = (item.children || []).map(child => {
              return child.id;
            });
          }
          return [...pre, item.id, ...nextLevelId, ...firstLevel];
        } else {
          return [...pre, ...firstLevel];
        }
      }, []);
    },
  },
  watch: {
    menuList: {
      handler(N) {
        const list = this.treeToArry(N || []);
        this.menuArray = list.map(item => {
          return {
            ...item,
            allChildren: list
              .filter(child => {
                return child.parents.includes(item.id);
              })
              .map(child => {
                return child.id;
              }),
          };
        });
        if (list.length) {
          this.init();
        }
      },
      immediate: true,
      deep: true,
    },
    '$route.path': {
      handler() {
        this.init();
      },
    },
  },
  methods: {
    ...mapActions('systemMenu', ['doSwitchTab']),
    handleExpandClick(item) {
      if (item.expand) {
        this.menuArray = this.menuArray.map(menu => {
          if (item.id === menu.id || item.allChildren.includes(menu.id)) {
            menu.expand = false;
          }
          return {
            ...menu,
          };
        });
      } else {
        item.expand = true;
      }
    },
    selectItem(item, fromTab) {
      if (!item.id) {
        return;
      }
      if (this.collapse === 'expand') {
        this.handleExpandClick(item);
      } else if (item.path) {
        // 如果页面跳转收起菜单
        this.clearHoverExpand();
      }
      if (item.path) {
        if (!fromTab) {
          this.doSwitchTab(item);
        }
        if (this.$route.path !== item.path) {
          this.$router.push({
            path: item.path,
          });
        }
      }
      this.menuArray = [...this.menuArray].map(menu => {
        const active = item.id === menu.id;
        // 点击没有path的菜单之前active的菜单转为childActive
        const childActive =
          menu.allChildren.includes(this.activeTab.id) ||
          (!item.path && this.activeTab.id === menu.id);
        return {
          ...menu,
          active,
          childActive: childActive && !active,
        };
      });
    },
    clearHoverExpand() {
      this.menuArray = this.menuArray.map(menu => {
        return {
          ...menu,
          expand: false,
        };
      });
    },
    handMouseleave(item) {
      this.leaveHandleTimer = setTimeout(() => {
        this.handleCollapseHover(item);
      }, 200);
    },
    handleCollapseHover(item) {
      clearTimeout(this.leaveHandleTimer);
      clearTimeout(this.enterHandleTimer);
      if (this.collapse === 'expand') {
        return;
      }
      this.enterHandleTimer = setTimeout(() => {
        if (!item.id) {
          this.clearHoverExpand();
          return;
        }
        const nextLevelId = (item.children || []).map(child => {
          return child.id;
        });
        const brothers = this.menuArray
          .filter(menu => {
            return (
              (item.parents || []).join(',') === (menu.parents || []).join(',')
            );
          })
          .map(menu => {
            return menu.id;
          });
        const parentsItems = this.menuArray.filter(menu => {
          return item.parents.includes(menu.id);
        });
        const parentsBrothers = this.menuArray
          .filter(menu => {
            return parentsItems.some(parent => {
              return (
                (parent.parents || []).join(',') ===
                (menu.parents || []).join(',')
              );
            });
          })
          .map(menu => {
            return menu.id;
          });
        this.menuArray = this.menuArray.map(menu => {
          return {
            ...menu,
            expand: [
              ...nextLevelId,
              ...item.parents,
              ...parentsBrothers,
              ...brothers,
            ].includes(menu.id),
          };
        });
      }, 100);
    },
    themeSwitch() {
      this.theme = this.theme === 'dark' ? 'light' : 'dark';
      setStorage('nav-menu-theme', this.theme);
    },
    collapseSwitch() {
      this.collapse = this.collapse === 'expand' ? 'collapse' : 'expand';
      setStorage('nav-menu-collapse', this.collapse);
      this.expandInit();
    },
    expandInit() {
      const activeTab = this.menuArray.find(item => {
        return item.path === this.$route.path;
      });
      if (this.collapse === 'collapse') {
        this.clearHoverExpand();
      } else if (activeTab && activeTab.parents) {
        // 展开父级
        this.menuArray = this.menuArray.map(menu => {
          return {
            ...menu,
            expand:
              activeTab.parents.includes(menu.id) || activeTab.id === menu.id,
          };
        });
      }
    },
    itemStyle(item, index) {
      let backgroundColor = ['#3D424D', '#14161A', '#0b0b0c'];
      let defaultColor = '#0b0b0c';
      if (this.theme === 'light') {
        backgroundColor = ['#ffffff', '#ffffff', '#ffffff'];
      }
      defaultColor = '#ffffff';
      let height = '0';
      if (this.expandList.includes(item.id) || !item.level) {
        height = '40px';
      }
      if (this.collapse === 'expand') {
        return {
          paddingLeft: `${item.level * 16}px`,
          height,
          background: backgroundColor[item.level] || defaultColor,
          overflow: 'hidden',
        };
      } else {
        return {
          display:
            this.expandList.includes(item.id) || !item.level ? 'block' : 'none',
          background: backgroundColor[item.level] || defaultColor,
          left: `${item.left}px`,
          top: `${item.top}px`,
          zIndex: `${item.level * 2000 + index}`,
        };
      }
    },
    treeToArry(menus) {
      const array = [];
      const doLoop = (tree, level, parents, top) => {
        tree.forEach((item, index) => {
          const parentTop = top + 40 * (index + (level === 1 ? 1 : 0));
          array.push({
            ...item,
            level,
            parents,
            left: level * 240 - (level ? 200 : 0),
            top: parentTop,
            firsrChild: level === 1 && !index,
          });
          if (item.children && item.children.length) {
            doLoop(item.children, level + 1, [...parents, item.id], parentTop);
          }
        });
      };
      doLoop(menus, 0, [], 0);
      return array;
    },
    init() {
      if (this.inited) {
        return;
      }
      const path = this.$route.path;
      let targetPage = this.menuArray.find(item => {
        return path === item.path;
      });
      if (!targetPage) {
        targetPage = this.tabList.find(item => {
          return item.path === path;
        });
      }
      if (targetPage) {
        this.expandInit();
        this.selectItem(targetPage);
        this.inited = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './expand';
@import './collapse';
@import './dark';
@import './light';
</style>
