/**
 * @Description: vue config文件
 * @Author: jiangang
 * @Date: 2022-03-07
 * @LastEditTime: 15:02:09
 */
import router from '@/router';
import { Message } from 'element-ui';
import { mock, http } from '@/http';

const { menuList } = require('@/assets/data/menuList.js');
const state = {
  menuUpdateHook() { },
  maxTabs: 10,
  menuList: [],
  onTabClose: {},
  onTabClick: {},
  tabList: [],
  activeTab: {}
};

function updateRouter(array) {
  const currentPath = router.getRoutes().map(item => {
    return item.path;
  });
  function doLoop(list) {
    const menus = [];
    list.forEach(item => {
      const page = {
        path: item.url,
        name: item.functionName,
        meta: {
          title: item.functionName,
          keepAlive: true,
          // isFull: item.isFull,
          // requireAuth: item.requireAuth !== false,
        }
      };
      const menu = {
        title: item.functionName,
        icon: item.icon,
        path: item.url,
        name: item.functionName,
        requireAuth: false,
        keepAlive: true,
        id: item.id,
        children: []
      };
      const componentPath = item.component || item.url;
      if (componentPath) {
        page.component = resolve => require([`@/views${componentPath}`], resolve);
      }
      if (page.path && !currentPath.includes(page.path)) {
        router.addRoute(page);
      }
      if (item.subFunctionList && item.subFunctionList.length) {
        menu.children = doLoop(item.subFunctionList);
      }
      menus.push(menu);
    });
    return menus;
  }
  return doLoop(array);
}

const actions = {
  updateMenuList({ dispatch, commit }, type = 'business') {
    return new Promise((resolve, reject) => {
      commit('updateMenuList', []);
      if (type == 'development') {
        // 本地获取demo
        setTimeout(() => {
          commit('updateMenuList', menuList['business']);
          resolve(menuList['business']);
        }, 500);
      } else {
        // 获取菜单,'productSideList': ['1001001']为固定参数
        http.post('/open/function/user', { 'productSideList': ['1001001'] }).then(function(res) {
          if (res.subFunctionList && res.subFunctionList.length) {
            if (res.subFunctionList[0].subFunctionList.length) {
              commit('updateMenuList', res.subFunctionList[0].subFunctionList);
            }
          }
          resolve(res.subFunctionList);
        }).catch(err => {
          reject(err);
        });
      }
    });
  },
  updateTabList({ commit }, list) {
    commit('updateTabList', list);
  },
  doSwitchTab({ commit }, item) {
    if (!item) {
      // 所有tab已关闭
      const path = router?.history?.current?.fullPath;
      if (path !== '/welcome') {
        router.replace('/welcome');
      }
      // 清除已激活的tab
      commit('switchTab', {});
      return;
    }
    const paths = state.tabList.map(menu => {
      return menu.path;
    });
    if (state.tabList.length >= state.maxTabs && !paths.includes(item.path)) {
      Message({
        type: 'warning',
        showClose: true,
        message: '页面打开太多，为避免卡顿，请先关闭标签',
      });
    } else {
      commit('switchTab', item);
    }
  }
};

const mutations = {
  onTabClose(state, payload) {
    if (typeof payload === 'function') {
      state.onTabClose.common = payload;
    } else {
      const { id, hook } = payload;
      if (id && typeof hook === 'function') {
        state.onTabClose[id] = hook;
      } else {
        console.error('参数必须为function或者{id, hook: function}');
      }
    }
  },
  onTabClick(state, payload) {
    // state.onTabClick[id] = hook
    if (typeof payload === 'function') {
      state.onTabClick.common = payload;
    } else {
      const { id, hook } = payload;
      if (id && typeof hook === 'function') {
        state.onTabClick[id] = hook;
      } else {
        console.error('参数必须为function或者{id, hook: function}');
      }
    }
  },
  menuUpdateHook(state, hook) {
    state.menuUpdateHook = hook;
  },
  updateMenuList(state, list) {
    // 部分操作需要在菜单初始化完成之后才能做，此处预留一个钩子函数
    if (typeof state.menuUpdateHook === 'function') {
      state.menuUpdateHook(list);
    }

    state.menuList = updateRouter(list);
  },
  updateTabList(state, list) {
    state.tabList = [...list];
  },
  switchTab(state, item) {
    // 为了避免某些页面监听问题，页面跳转和tab显示各自分开做
    state.activeTab = { ...item };
    // 更新已有的
    let include = false;
    state.tabList = state.tabList.map(tab => {
      if (item.path === tab.path) {
        include = true;
        return {
          ...tab,
          ...item
        };
      }
      return tab;
    });
    if (!include && item?.path) {
      state.tabList.push(item);
    }
  },
  deleteTab(state, delItem) {
    state.tabList = state.tabList.filter(item => {
      return item.path !== delItem.path;
    });
  }
};

const getters = {
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
