<template>
  <div class="no-permission flex-column flex-center">
    <img class="bg-img" src="./no-permission.png" alt="">
    <div class="title">暂无权限</div>
    <div class="des">您当前绑定的系统账号暂无系统权限，请通过OA进行权限申请。
      如有疑问，请联系纵队信息负责人咨询。</div>
<!--    <div class="des">如需观看课程，请在飞书工作台，搜索 <span>[知新]</span>进行观看</div>-->
  </div>
</template>

<script>
export default {
  name: 'noPermission'
}
</script>

<style lang="scss" scoped>
  .no-permission {
    width: 100%;
    height: 100%;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    text-align: center;
    box-sizing: border-box;
    padding-bottom: 100px;
    .bg-img {
      width: 400px;
    }
    .title {
      width: 112px;
      height: 28px;
      font-size: 28px;
      font-weight: 400;
      color: #666666;
      line-height: 28px;
    }
    .des {
      margin-top: 12px;
      width: 455px;
      height: 48px;
      font-size: 16px;
      font-weight: 400;
      color: #999999;
      line-height: 24px;
    }
  }
</style>
