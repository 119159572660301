/**
 * @Description: router入口文件
 * @Author: huping
 * @Date: 2021-08-06 11:07:15
 * @LastEditTime: 2021-08-26 16:19:30
 */

import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';
import store from '@/store';
import { getStorage, setStorage } from '@utils/storage';
import { http } from '@/http';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

let menuInited = false;
function generateMenu(menuList, to, from, next) {
  if (menuList.length === 0 && !menuInited) {
    const updateType = getStorage('left-menu-type') || 'business';
    // 更新左侧菜单
    store.dispatch('systemMenu/updateMenuList', updateType).then(() => {
      menuInited = true;
      next({
        ...to,
        replace: true
      });
    }).catch((err) => {
      console.log(err);
      menuInited = false;
      // test code
      if (err.code == 10001) {
        next({
          ...to,
          replace: true
        });
        return;
      }

      // 菜单初始化失败
      next({
        path: '/no-permission',
        replace: true
      });
    });
  }
}

function intercept(menuList, to, next) {
  let currentPage = {};
  const loop = (list) => {
    for (const item of list) {
      if (item.path === to.path) {
        currentPage = item;
        return;
      }
      if (item.children && item.children.length) {
        loop(item.children);
      }
    }
  };
  // 鉴权
  const checkAuthor = (list) => {
    if (to.meta.requireAuth) {
      // list为已经初始化之后的菜单列表
      loop(list);
      // TODO 对currentPage鉴权，拦截跳转的务必return，如下：
      if (currentPage.name === 'index4') {
        router.replace('/no-permission');
        return;
      }
    }
    store.dispatch('global/updatePreviousRouter', from);
    Vue.prototype.$sendPoint && Vue.prototype.$sendPoint.pageViewEvent(to, from);
    next();
  };

  if (menuList.length) {
    checkAuthor(menuList);
  } else {
    // 项目未完成初始化
    store.commit('systemMenu/menuUpdateHook', (menuList) => {
      checkAuthor(menuList);
    });
  }
}

// 登录路由控制(routes中设置路由meta.requireAuth)
router.beforeEach((to, from, next) => {
  if (to.path == '/idmlogin') {
    if (to.query.loginCode) {
      store.dispatch('userInfo/login', to).then(() => {
        next({
          ...to,
          replace: true
        });
      });
    }
    else {
      next({
        path: '/no-permission',
        replace: true
      });
    }
    return;
  }

  const menuList = store.state.systemMenu.menuList;
  if (getStorage('TOKEN')) {
    http.defaults.headers.common['TOKEN'] = getStorage('TOKEN');
    if (menuList.length == 0) {
      generateMenu(menuList, to, from, next);
    }
    else {
      if (to.matched.length == 0) {
        next({
          path: '/404',
          replace: true
        });
      }

      // intercept(menuList, to, next);

      // 如果有对应的路由，直接进入，否则跳转回主页
      if (to.matched.length > 0 && to.path != '/login') {
        next();
      } else {
        next({
          path: '/',
          replace: true
        });
      }
    }
  }
  else {
    // 跳转idm登录页面
    store.dispatch('userInfo/login', to).then(() => {
      next({
        path: to.path,
        replace: true
      });
    }).catch(() => {
      // 空处理函数避免控制台输出
    });
  }
});

export default router;

