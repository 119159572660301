<template>
    <div class="crumbs-page">
      <div v-if="title || routeName" class="crumbs-title">
        <slot name="header">
          <span>{{title || routeName}}</span>
        </slot>
      </div>
      <slot></slot>
    </div>
</template>

<script>
export default {
  name: 'crumbs',
  props: {
    title: {
      type: String
    }
  },
  data() {
    return {
      routeName: ''
    }
  },
  watch: {
    $route: {
      handler(N) {
        this.routeName = N.meta.title
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .crumbs-page {
    width: 100%;
    padding: 32px 16px;
    box-sizing: border-box;
    .crumbs-title {
      margin-bottom: 24px;
      span {
        font-size: 16px;
        font-weight: bold;
        color: rgba(0,0,0,0.85);
        line-height: 16px;
      }
    }
  }
</style>
