function plugin(Vue) {
  if (plugin.installed) {
    return
  }
  !Vue.prototype.$auth && Object.defineProperties(Vue.prototype, {
    $auth: {
      get() {
        const _this = this
        return (permissions) => {
          const [permission, action] = permissions.split('.')
          const permissionList = _this.$store.state.userInfo.roles
          return permissionList.find((val) => {
            return val.permissionId === permission
          })?.actionList.findIndex((val) => {
            return val === action
          }) > -1
        }
      }
    }
  })
}

export default plugin