<template>
  <div class="nav-tab flex-row">
    <div class="tab-item flex-row flex-center"
         draggable="true"
         @dragstart="dragstart"
         @dragenter="dragenter"
         @dragend="dragend"
         @contextmenu.prevent="openMenu($event, index)"
         :data-index="index"
         :class="itemClass(item, index)"
         @click="tabClick(item)"
         v-for="(item, index) in tabList" :key="item.path">
      <div class="name flex1">{{item.title}}</div>
      <i class="el-icon-close" @click.stop="remove(item)"></i>
    </div>
    <div class="img-menu-container"
         v-if="menuVisible"
         @click.stop="() => {}"
         @contextmenu.prevent="() => {}"
         :style="{left: imgPosition.left + 'px', top: imgPosition.top + 'px'}">
      <div @click.stop="closeTab('self')" class="menu-btn">关闭当前</div>
      <div @click.stop="closeTab('other')" class="menu-btn" v-if="tabList.length > 1">关闭其他标签</div>
      <div class="menu-btn disabled" v-else>关闭其他标签</div>
      <div class="menu-btn disabled" v-if="operateIndex === tabList.length - 1">关闭右侧标签</div>
      <div @click.stop="closeTab('right')" class="menu-btn" v-else>关闭右侧标签</div>
      <div @click.stop="closeTab('all')" class="menu-btn">关闭所有</div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'tabMenu',
  data() {
    return {
      fromIndex: -1,
      toIndex: -1,
      imgPosition: {
        left: 0,
        top: 0
      },
      menuVisible: false,
      operateIndex: 0
    }
  },
  methods: {
    ...mapActions('systemMenu', ['doSwitchTab']),
    openMenu(e, index) {
      this.menuVisible = true
      this.operateIndex = index
      const {pageX, pageY} = e
      this.imgPosition.left = pageX
      this.imgPosition.top = pageY
    },
    closeTab(type) {
      switch (type) {
        case 'self':
          this.closeTabItem(this.operateIndex)
          break;
        case 'other':
          this.tabList.forEach((item, index) => {
            if (this.operateIndex !== index) {
              this.remove(item)
            }
          })
          break
        case 'right':
          this.tabList.forEach((item, index) => {
            if (this.operateIndex < index) {
              this.remove(item)
            }
          })
          break
        default:
          // closeAll
          this.tabList.forEach((item, index) => {
            this.remove(item)
          })
      }
      console.log(type)
      console.log(this.operateIndex)
      this.menuVisible = false
    },
    itemClass(item, index) {
      return {
        active: item.path === this.activeTab.path,
        moving: index === parseInt(this.fromIndex, 10),
        'behind-target': index === parseInt(this.toIndex, 10) && this.toIndex > this.fromIndex,
        'front-target': index === parseInt(this.toIndex, 10) && this.toIndex < this.fromIndex
      }
    },
    dragstart(e) {
      if (e && e.toElement && e.toElement.dataset) {
        this.fromIndex = e.toElement.dataset.index
      }
    },
    dragenter(e) {
      if (e && e.fromElement && e.fromElement.dataset) {
        this.toIndex = e.fromElement.dataset.index
      }
    },
    dragend() {
      const tabList = [...this.tabList]
      const {fromIndex, toIndex} = this
      if (fromIndex >= 0 && toIndex >= 0) {
        const deleteItem = tabList.splice(fromIndex, 1)
        tabList.splice(toIndex, 0, deleteItem[0])
        this.$store.commit('systemMenu/updateTabList', tabList)
      }
      this.fromIndex = -1
      this.toIndex = -1
    },
    tabClick(item) {
      const nextStep = () => {
        this.doSwitchTab(item);
        this.toTabPage(item)
        this.$bus.$emit('tabClick', item);
      }
      if (typeof this.onTabClick === 'function') {
        this.onTabClick(item, nextStep)
      } else {
        nextStep()
      }
    },
    toTabPage(item) {
      const {path, query = {}} = item
      if (this.$route.path !== path) {
        this.$router.push({
          path,
          query
        })
      }
    },
    closeTabItem(index) {
      const current = this.tabList[index]
      const nextTab = this.tabList[index + 1]
      const preTab = this.tabList[index - 1]
      const nextStep = () => {
        this.$store.commit('systemMenu/deleteTab', current);
        if (current.path === this.activeTab.path) {
          // 当关闭页面处于打开状态时
          const activeItem = nextTab || preTab
          this.doSwitchTab(activeItem)
          if (activeItem) {
            this.toTabPage(activeItem)
          }
        }
      }
      const thisHook = this.onTabClose[current.id]
      const commonHook = this.onTabClose.common
      const code = (typeof commonHook === 'function' ? '1' : '0') + (typeof thisHook === 'function' ? '1' : '0')
      switch (code) {
        case '11':
          commonHook(current, () => {
            console.log('全局拦截被当前tab自定义拦截覆盖')
          })
          thisHook(current, nextStep)
          break
        case '10':
          commonHook(current, nextStep)
          break
        case '01':
          thisHook(current, nextStep)
          break
        default:
          nextStep()
      }
    },
    remove(tab) {
      this.tabList.forEach((item, index) => {
        if (item.path === tab.path) {
          this.closeTabItem(index)
        }
      })
    }
  },
  computed: {
    ...mapState('systemMenu', ['tabList', 'activeTab', 'onTabClose', 'onTabClick']),
  },
  mounted() {
    document.addEventListener('click',() => {
      this.menuVisible = false
    })
  }
}
</script>

<style lang="scss" scoped>
.nav-tab {
  .moving {
    opacity: 0.2;
  }
  .behind-target:after {
    content: '';
    width: 2px;
    height: 100%;
    background: #666666;
    position: absolute;
    right: 0;
  }
  .front-target:after {
    content: '';
    width: 2px;
    height: 100%;
    background: #666666;
    position: absolute;
    left: 0;
  }
  .tab-item {
    height: 40px;
    min-width: 110px;
    padding: 0 14px;
    box-sizing: border-box;
    cursor: pointer;
    margin-right: 2px;
    max-width: 250px;
    background: rgba(217, 217, 217, 0.3);
    border-radius: 4px 4px 0 0;
    font-size: 14px;
    color: #333333;
    position: relative;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.05);
    .name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      text-align: center;
    }
    i {
      font-size: 16px;
      margin-left: 8px;
      border-radius: 2px;
    }
  }
  .active {
    background: #FFFFFF;
    color: $primary;
    box-shadow: none;;
  }

  .img-menu-container {
    position: fixed;
    z-index: 999;
    box-sizing: border-box;
    left: 0;
    top: 0;
    width: 140px;
    background-color: #ffffff;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    overflow: hidden;
    .menu-btn {
      width: 100%;
      padding: 8px 10px;
      box-sizing: border-box;
      cursor: pointer;
      &:hover {
        background: $primary;
        color: #ffffff;
      }
      &.disabled {
        color: $color-light;
        cursor: not-allowed;
        &:hover {
          background: #ffffff;
        }
      }
    }
  }
}
</style>
