<template>
  <div class="table-page">
    <slot></slot>
    <div v-if="total" class="page-container flex-row flex-item">
      <slot name="foot"></slot>
      <div class="flex1"></div>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        v-bind="paginationProps"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: 'nh-pagination',
  props: {
    total: {
      type: Number,
      default: 0
    },
    loadFunc: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      currentPage: 1,
      params: {
        pageSize: 10,
        pageNum: 1
      }
    }
  },
  methods: {
    handleSizeChange(pageSize) {
      this.params = {
        pageSize,
        pageNum: 1
      }
      this.currentPage = 1
      this.loadFunc(this.params)
    },
    handleCurrentChange(pageNum) {
      this.params.pageNum = pageNum
      this.currentPage = pageNum
      this.loadFunc(this.params)
    },
    refresh() {
      this.params = {
        pageSize: 10,
        pageNum: 1
      }
      this.loadFunc(this.params)
    }
  },
  mounted() {
    this.loadFunc(this.params)
  },
  computed: {
    paginationProps() {
      return {
        'page-sizes': [10, 20, 30, 40],
        'page-size': 10,
        layout: 'total, sizes, prev, pager, next, jumper',
        ...this.$attrs,
      }
    }
  }
}
</script>

<style scoped>
  .page-container {
    margin-top: 20px;
    height: 64px;
  }
</style>
