/**
 * @Description: vue config文件
 * @Author: jiangang
 * @Date: 2022-03-07
 * @LastEditTime: 13:43:15
 */
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

/**
 *  webpack 的 api ，通过该函数可以获取一个上下文，从而实现工程自动化（遍历文件夹的文件，从中获取指定文件，自动导入模块）
 *  require.context(directory, useSubdirectories, regExp)
 *  directory: 要查找的文件路径
 *  useSubdirectories: 是否查找子目录
 *  regExp: 要匹配文件的正则
 * */
const reqiureContext = require.context('./modules', false, /\.js$/);
const modules = {};

reqiureContext.keys().forEach((key) => {
  const name = key.slice(2, -3); // 得到 state的名称
  const moduleConfig = reqiureContext(key).default || reqiureContext(key);
  modules[name] = moduleConfig;
});

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  modules,
});
