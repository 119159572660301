/**
 * @Description: Login
 * @Author: huping
 * @Date: 2021-08-20 16:22:37
 * @LastEditTime: 2022-11-11 15:34:41
 */

<template>
  <section :class="$style.login">
    <div :class="$style['login-background']" id="particles-js">
      <canvas class="particles-js-canvas-el" style="width: 100%; height: 100%;" width="472" height="625"></canvas>
    </div>
    <div :class="$style['login-continer']">
      <div :class="$style['login-continer__logo']">
        <div :class="$style['login-continer__logo--top']">
          <img :src="images.liuheLogo" alt="">
        </div>
        <div :class="$style['login-continer__logo--content']">
          <img src="../../assets/imgs/login-logo.png" alt="">
          <p>
            <span>数字科技大部</span>
            <span>信箱后台管理系统</span>
          </p>
        </div>
      </div>
      <div :class="$style['login-continer__login']">
        <p :class="$style['login-continer__login--text']">{{loginFast ? '快捷登录' : '欢迎登录'}}</p>
        <div :class="$style['login-continer__login--fast']" v-if="loginFast">
          <div class="flex-row">
            <div :class="$style['login-continer__login--fast--item']" @click="quickLogin('IDM')">
              <p><img :src="images.idmLogin" alt=""></p>
              <p><span>OA登录</span></p>
            </div>
            <div :class="$style['login-continer__login--fast--item']" @click="quickLogin('feishu')">
              <p><img  :src="images.feishuLogin" alt=""></p>
              <p><span>飞书登录</span></p>
            </div>
          </div>
        </div>
        <div :class="$style['login-continer__login--form']" v-else>
          <el-form :model="userInfo" :rules="rules" ref="form">
            <el-form-item prop="username">
              <div class="flex-row flex-item">
                <el-input v-model="userInfo.username" placeholder="请输入用户名"></el-input>
              </div>
            </el-form-item>
            <el-form-item prop="password">
              <div class="flex-row flex-item">
                <el-input v-model="userInfo.password" type="password" placeholder="请输入密码"></el-input>
              </div>
            </el-form-item>
            <el-form-item prop="code">
              <div class="flex-row flex-item">
                <el-input v-model="userInfo.code" placeholder="请输入验证码"></el-input>
                <img class="captcha-image" @click="getCode" v-if="captchaImage.img" :src="`data:image/gif;base64,${captchaImage.img}`" alt="">
              </div>
            </el-form-item>
            <div class="flex-row">
              <div class="flex1"></div>
              <el-checkbox class="remember-check" v-model="userInfo.remember">记住账户</el-checkbox>
            </div>
            <div class="flex-row flex-center">
              <el-button :loading="loading" class="flex1" type="primary" @click="submit('rules')">登录</el-button>
            </div>
          </el-form>
        </div>
      </div>
    </div>
    <div :class="$style['login-slogan']">
      <p :class="$style['login-slogan__line']"><span></span><span></span></p>
      <p :class="$style['login-slogan__text']">0876数科出品</p>
      <p :class="$style['login-slogan__line']"><span></span><span></span></p>
    </div>
  </section>
</template>

<script>
import 'particles.js'
import {getStorage, setStorage} from '@utils/storage';
import loginMix from '@views/login/loginMix'
const images = {
  liuheLogo: require('@/assets/imgs/login/liuhe_logo.svg'),
  idmLogin: require('@/assets/imgs/login/login_idm.png'),
  feishuLogin: require('@/assets/imgs/login/login_feishu.png')
}
export default {
  name: 'Login',
  mixins: [loginMix],
  data() {
    return {
      images,
      userInfo: {
        username: '',
        password: '',
        code: '',
        remember: false,
      },
      captchaImage: {
        img: '',
        uuid: ''
      },
      rules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
        ],
      },
      loading: false,
      loginFast: false,
      // TODO 仅为demo展示使用
      isLocal: false
    }
  },
  methods: {
    mockCheck() {
      // 此处仅为demo展示使用
      this.loading = true
      this.$mock.get('/global/mock-check').then(() => {
        // 线上获取demo
        this.getCode()
      }).catch(() => {
        // 本地获取demo
        this.isLocal = true
      }).finally(() => {
        this.loading = false
      })
    },
    getCode() {
      this.$mock.get('/verificationCode').then(res => {
        const {img, uuid} = res
        this.captchaImage = {img, uuid}
      }).catch(err => {
        console.log(err)
      })
    },
    submit() {
      if (this.$sendPoint) {
        this.$sendPoint.userLoginEvent({ user_id: 'user_idxxx', user_name: 'user_name', user_mobile: '188888' });
      }
      this.$refs.form.validate((isFinish) => {
        if (!isFinish) {
          return
        }
        if (this.isLocal) {
          setStorage('login-info', this.userInfo.remember ? this.userInfo : {})
          this.$router.push({
            path: '/welcome'
          })
          return
        }
        const {username, code, password} = this.userInfo
        this.loading = true
        this.$mock.post('/login', {
          code,
          password,
          username,
          uuid: this.captchaImage.uuid
        }).then(res => {
          setStorage('authorization', `Bearer ${res.token}`)
          setStorage('login-info', this.userInfo.remember ? this.userInfo : {})
          this.$router.push({
            path: '/welcome'
          })
        }).catch(err => {
          this.getCode()
          this.$message({
            message: err.msg || '登录失败',
            type: 'error',
            showClose: true
          })
        }).finally(() => {
          this.loading = false
        })
      })
    }
  },
  mounted() {
    this.mockCheck()
    // 背景元素
    window.particlesJS.load('particles-js', './particles.json', () => {})
    const userInfo = getStorage('login-info')
    if (userInfo && userInfo.remember) {
      Object.assign(this.userInfo, userInfo)
    }
    setStorage('authorization', '')
  },
  created() {
    // 两种模式登录 择其一
    const { query: { type = '0' } } = this.$route
    this.loginFast = type === '1'
  },
}
</script>
<style lang="scss" scoped>
  .captcha-image{
    height: 38px;
    margin-left: 10px;
    border: 1px solid #DCDFE6
  }
  .remember-check {
    margin-bottom: 5px;
  }
</style>
<style lang="scss" module>
  .login {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-repeat: no-repeat;
    &-background {
      position: absolute;
      width: 100%;
      height: 100%;
      background: #090C44;
    }
    &-continer {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 576px;
      height: 350px;
      transform: translate3d(-50%, -50%, 0);
      animation: login-fadein-down .3s;
      border-radius: 8px;
      text-align: center;
      overflow: hidden;
      box-shadow: 2px 2px 5px 5px rgba(0, 0, 0, .1);
      z-index: 1;
      display: flex;
      &__logo {
        width: 216px;
        background: $primary;
        position: relative;
        overflow: hidden;
        &--top {
          text-align: left;
          padding: 8px 0 10px 10px;
        }
        &--content {
          padding-top: 60px;
          p {
            padding-top: 20px;
            font-size: 16px;
            font-weight: 800;
            color: #fff;
            line-height: 1.5;
            span {
              display: block;
              &:first-child {
                font-size: 18px;
              }
            }
          }
        }
      }
      &__login {
        flex: 1;
        background: #fff;
        padding: 20px 40px;
        color: #333333;
        &--text {
          font-size: 18px;
          font-weight: 800;
          margin-top: 20px;
        }
        &--fast {
          margin-top: 80px;
          &--item {
            flex: 1;
            font-size: 14px;
            font-weight: bold;
            line-height: 40px;
            color: #666666;
            cursor: pointer;
            img {
              display: block;
              width: 70px;
              height: 70px;
              border-radius: 100%;
              margin: auto;
              margin-bottom: 10px;
            }
          }
        }
        &--form {
          padding-top: 25px;
          &-input {
            :global {
              .el-form-item {
                width: 100%;
                // .el-input-group--append .el-input__inner {
                //   border-right: none;
                //   transition: border-color 0s cubic-bezier(.645,.045,.355,1);
                // }
                // .el-input__inner:focus ~ .el-input-group__append {
                //   border-color: $primary;
                // }
                // .el-input-group__append {
                //   background: transparent;
                //   border-left: none;
                //   padding: 0 10px 0 0;
                //   .el-input__append--eye {
                //     cursor: pointer;
                //     padding-left: 10px;
                //     border-left: 1px solid #ccc;
                //     i {
                //       line-height: 33px;
                //       transform: rotate(180deg);
                //     }
                //   }
                // }
                // &.is-error {
                //   .el-input-group__append {
                //     border-color: #FF3B30;
                //   }
                //   .el-input__inner:focus ~ .el-input-group__append {
                //     border-color: #FF3B30;
                //   }
                // }
              }
            }
          }
          &-forget {
            color: #666666;
            text-align: right;
            cursor: pointer;
          }
          &-btn {
            button {
              margin-top: 20px;
              width: 100%;
            }
          }
        }
      }
    }
    &-slogan {
      position: absolute;
      bottom: 20px;
      left: 50%;
      width: 230px;
      height: 30px;
      transform: translate3d(-50%, -50%, 0);
      display: flex;
      &__line {
        width: 50px;
        padding-top: 11px;
        span {
          width: 24px;
          height: 1px;
          display: block;
          border: 1px solid rgba(255, 255, 255, .6);
          &:last-child {
            width: 10px;
            margin-top: 4px;
          }
        }
        &:first-child {
          transform: rotate3d(0, 10, 1, 180deg);
        }
      }
      &__text {
        flex: 1;
        color: rgba(255, 255, 255, .6);
        line-height: 30px;
        text-align: center;
        letter-spacing: 2px;
      }
    }
  }

  @keyframes login-fadein-down {
    from {
      opacity: 0;
      transform: translate3d(-50%, -60%, 0);
    }

    to {
      opacity: 1;
      transform: translate3d(-50%, -50%, 0);
    }
  }
</style>
