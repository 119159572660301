/**
 * @Description: vue config文件
 * @Author: jiangang
 * @Date: 2022-03-07
 * @LastEditTime: 10:01:02
 */

import axios from 'axios';
import {getStorage} from '@/utils/storage';
import {responseError, responseResolve} from './config';
import {apiBase} from '../../config'

const config = {
  withCredentials: false,
  timeout: 1000 * 60,
  headers: {
    // 'Content-Type': 'application/x-www-form-urlencoded',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    Accept: 'application/json',
  },
}
const http = axios.create({
  baseURL: apiBase,
  ...config,
});
const {protocol, hostname} = window.location
const mock = axios.create({
  baseURL: `${protocol}//${hostname}:8747/`,
  ...config,
});
const httpInit = (axios = http) => {
  axios.interceptors.request.use(
    config => {
      const authorization = getStorage('authorization')
      if (authorization) {
        config.headers.authorization = authorization;
      }
      return config;
    },
    err => Promise.reject(err),
  );
  axios.interceptors.response.use(
    responseResolve,
    responseError,
  );
  return axios;
}
httpInit()
httpInit(mock)
export {
  http,
  mock
};
