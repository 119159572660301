/**
 * @Description: 项目配置入口文件
 * @Author: jiangang
 * @Date: 2022-03-04
 * @LastEditTime: 14:18:43
 * @LastEditTime: 14:18
 */

const env = process.env.NODE_ENV || 'production';
const hostMap = {
  development: {
    // 本地启动时代理的地址
    apiBase: '/api/v2/corax-op/',
  },
  uat: {
    // 本地启动时代理的地址
    apiBase: '/api/v2/corax-op/',
  },
  production: {
    // 线上打包时的host地址，一般不改动
    apiBase: '/api/v2/corax-op/',
  },
};

const agentList = {
  // 本地联调临时配置
  // '/api/hcm-system/': {
  //   target: 'http://172.18.11.199:8082/',
  //   changeOrigin: true,
  //   pathRewrite: { '^/api/hcm-system': '/api' }
  // },
  '/api/': {
    target: 'http://gateway-openapi-ai.dev.aiwork.breedscn.cc/',
    // target: 'http://plan-web-uat.pigkeeping.cn/api/v2/corax-op/',
    changeOrigin: true,
    // pathRewrite: { '^/api/open': '/open' }
  }
}

module.exports = {
  apiBase: hostMap[env] && hostMap[env].apiBase,
  outputDir: 'dist',
  proxy: {
    ...agentList
  },
  publicPath: '/'
};
